import React from 'react'
import './testimonials.css'
import egobetter2 from '../../assets/egobetter2.jpg'
// import Swiper core and required modules








const data = [
  {
    avatar: egobetter2,
    name: 'tits',
    review: '22222222222222222222222222222'
  },
  {
    avatar: egobetter2,
    name: 'tits2',
    review: '33333333'
  },
  
]


const testimonials = () => {
  return (
    <section id='testimonials' >
      
      
      
      <article className="container testimonials__container">
        
        <article className="testimonial">
          
          <div className="review__name"><h2> <i>"Highly skilled, patient and very reliable. Will work with him again!"</i> <br></br> <br></br><small> <i>egobetter</i> <br></br></small></h2></div>
          <div className="review__text_egobetter"><i></i></div>
          <div className="fiver__best_seller"> Fiverr Best Seller</div>
        </article>
       
      </article>
    </section>
  )
}

export default testimonials