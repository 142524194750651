import React from 'react'
import './footer.css'
import {BsInstagram} from 'react-icons/bs'

const footer = () => {
  return (
    <footer>
      

      <ul className='permalinks'>
        {/*(<li><a href="#">Home </a></li>
        <li><a href="#about">About </a></li>
        <li><a href="#services">Services </a></li>
        <li><a href="#portfolio">Portfolio </a></li>
        <li><a href="#testimonials">Testimonials </a></li>
  <li><a href="#">Contact </a></li>}*/}
       </ul>

      

       <div className="footer__copyright">
        <small>&copy; Titan Array Media. All rights reserved.</small>
       </div>
    </footer>
    
  )
}

export default footer