import React from 'react'
import './portfolio.css'
import anom from '../../assets/anom.png' 
import titanbanner from '../../assets/titanbanner.png'
import seville from '../../assets/seville.jpg'
import videobanner6 from '../../assets/videobanner6.png'
import campaign_tile6 from '../../assets/campaign_tile6.png'
import { BiAlignMiddle } from 'react-icons/bi'
import new_look2 from '../../assets/new_look2.png'
import s3 from '../../assets/s3.png'
import bannerreplace from '../../assets/bannerreplace.png'




const portfolio = () => {
  return (
    <section id='portfolio'>
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      
      
      <div className="phrase__atop_portfolio"><h6> </h6></div>
      
      <img src={bannerreplace} alt=""/>
       <h5>  </h5>
      
      <div className="container portfolio__container_anom">
        {/*<article className='portfolio__item'>
          <div className="portfolio__item-image">
           
          </div>

          
         
         
          
          
  </article>*/}
        
 
     
                              
      </div>
      
      
      
      
      <div className="container portfolio__container_anom">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            
          </div>
          <h6>Launching soon</h6>
          <h5>Contact us today, and we can promptly schedule a consultation for your business. <br></br><br></br>Whether your requirements call for a concise 10-second video advertisement or an attention-grabbing organic Instagram promotion, we offer a diverse range of services, all of which can be meticulously tailored to align seamlessly with your business's unique requirements and brand identity.
          
          <br></br>
          <br></br><br></br>
          <br></br>
          
          </h5>
          <br></br><br></br><br></br><br></br>
          <center><iframe width="682" height="1213" src="https://www.youtube.com/embed/QlCwAaKoshQ" title="Welcome to Titan Array Media" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></center>
          
          <br></br><br></br><br></br><h5> 
          <br></br><br></br><center>{/*For inquiries, please reach out via email.*/} </center> </h5><br></br><br></br>
          {/* <div className="portfolio__item-cta"> 
            
             <a href="https://youtube.com/shorts/QlCwAaKoshQ?feature=share" className='btn btn-primary' target='_blank'>View an example</a> 
  </div>*/}
          
        </article>
        
        
        
        
        
      </div>
      
      </section>
  )
}

export default portfolio