import React from 'react'
import './header.css'
import CTA from './CTA'
import banner from '../../assets/titanbanner.png'
import HeaderSocial from './HeaderSocials'
import titanlogo2 from '../../assets/titanlogo2.png'
import logo from '../../assets/logo.png'
const header = () => {
  return (
    <header>
      
          
        <div className="container header__container">
          <div className="titan__logo"> <img src={logo} alt="" />
          </div>
          <br /><br/><br /><br></br>
        
          
          
          <h6 className="text-light"> Organic visual design, made easy.</h6>
          {/*<CTA />*/}
          <HeaderSocial />
          <><br /><br/><br /><br/><br /><br/><br /></>
          
        </div>

    </header>
  )
}

export default header