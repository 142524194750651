import React from 'react'
import './nav.css'
import {GoHome} from 'react-icons/go'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBookmark} from 'react-icons/bi'
import {VscTools} from 'react-icons/vsc'
import {TbMessageCircle} from 'react-icons/tb'
import {useState} from 'react'



const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')

  return (
    <nav> 
      <a href="#" onClick={() => setActiveNav ('#')} className={activeNav === '#' ? 'active' : ''}><GoHome/></a>
      {/*<a href="#about" onClick={() => setActiveNav ('#about')} className={activeNav === '#about' ? 'active' : ''} ><AiOutlineUser/></a>
      <a href="#portfolio" onClick={() => setActiveNav ('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><BiBookmark/></a>
  <a href="#services" onClick={() => setActiveNav ('#services')} className={activeNav === '#services' ? 'active' : ''}><VscTools/></a>*/}
      <a href="#contact" onClick={() => setActiveNav ('#contact')} className={activeNav === '#contact' ? 'active' : ''}><TbMessageCircle/></a>
    </nav>
  )
}

export default Nav