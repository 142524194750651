import React from 'react'
import {BsFillCloudRainHeavyFill} from 'react-icons/bs'
import {BsMusicNoteList} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        {/*<a href="https://www.met.ie/" target="_blank"> <BsFillCloudRainHeavyFill/></a>*/}
            
    </div>     
  )
}

export default HeaderSocials