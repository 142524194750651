import React from 'react'
import './contact.css'
import {AiOutlineMail} from 'react-icons/ai'

const Contact = () => {
  return (
    <section id='contact'>
      
      
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <AiOutlineMail className='contact__option-icon'/>
            <h6>shanotole@gmail.com</h6>
            
          </article>
          
        
        </div>
        {/*END OF CONTACT OPTIONS */}
      </div>
      

      </section>
  )
}

export default Contact